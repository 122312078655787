const US_ABDOMEN_PELVIS_MALE = `US ABDOMEN AND PELVIS

Findings:
The liver is homogenous and smooth outline. The liver is normal in size. No focal liver
lesion.
No intra or extrahepatic biliary duct dilatation.
Portal vein is patent and normal in caliber.
Gall bladder is well distended. No GB wall thickening, pericholecystic fluid or calculus.
Visualised pancreas and spleen are normal.
Both kidneys are normal in size. No hydronephrosis or calculus.
Urinary bladder is normal and well distended.
No ascites.
Prostate is not enlarged.

Impression:
Normal ultrasound abdomen and pelvis.

`
export default US_ABDOMEN_PELVIS_MALE;
