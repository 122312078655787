const PARANASAL_SINUS = `CT PARANASAL SINUS

FINDINGS:
Mucosal thickening seen in left ethmoidal sinus.
Other paranasal sinuses are clear.
Both osteomeatal complex (OMC) are patent.
Bilateral inferior nasal turbinate hypertrophy.
No bony erosion.
Both mastoid air cells are well aerated.
Carotid canals are normal.

No significant abnormality seen in the visualized brain parenchyma.

Impression :
Bilateral inferior nasal turbinate hypertrophy.
`

export default PARANASAL_SINUS;
