const CECT_RENAL = `NUR FARAHIN BINTI MD YASIM 910413105162

CECT Renal 4 phase done on 7.3.2023

Findings:
Right kidney:
BPL measures 10.6cm.
No focal solid or cystic lesion seen.
No renal or ureteric calculus.
No hydronephrosis or hydroureter.
No perinephric or periureteric stranding.

Left kidney:
BPL measures 10.7cm.
No focal solid or cystic lesion seen.
No renal or ureteric calculus.
No hydronephrosis or hydroureter.
No perinephric or periureteric stranding.

The urinary bladder is well distended .No calculus or bladder wall thickening.
The uterus is anteverted and appear normal in size.

The liver is normal in size. No focal liver lesion seen.
The gallbladder is well distended. No calculus ,wall thickening or pericholecystic fluid.
The pancreas, bilateral adrenals and spleen appear grossly normal.
No dilated bowel.
No significant mesenteric nodes seen.
No ascites.

Visualised lung bases are clear.
No suspicious bone lesions.

Impression:
No focal renal lesion or urolithiasis.

`

export default CECT_RENAL;
