const LIMB_XRAY = `XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX  RADIOGRAPH (AP AND LATERAL)

Findings:
No fracture.
No dislocation.
Soft tissue are unremarkable.

Impression:
No radiographic evidence of fracture or dislocation.
`

export default LIMB_XRAY;