const CT_ABDOMEN = `CT ABDOMEN

Findings:
The liver is normal in size. No focal liver lesion seen.
The gallbladder is well distended. No calculus ,wall thickening or pericholecystic fluid.
The pancreas, bilateral adrenals and spleen appear grossly normal.
Both kidney is normal. No focal lesion seen.
No dilated bowel.
No significant mesenteric nodes seen.
The urinary bladder is well distended .No calculus or bladder wall thickening.
Prostate is not enlarged.
No focal collection or free fluid seen at right iliac fossa region.
No ascites.

Visualised lung bases are clear.
No suspicious bone lesions.


Impression:
No CT evidence of acute appendicitis.
`

export default CT_ABDOMEN;
