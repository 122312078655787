import { assert, async } from "pdfjs-dist";
import pdfMake from "pdfmake/build/pdfmake";
import vfs from "./fonts/vfs_fonts";
import FotterImage from "../../../assets/images/fotter.png";
import WaterMark from "../../../assets/images/watermark/WATERMARK.jpg"; //tukar report template - watermark


pdfMake.vfs = vfs;

pdfMake.fonts = {
  Roboto: {
    normal: "NimbusSanL-Reg.otf",
    bold: "NimbusSanL-Bol.otf",
    italics: "NimbusSanL-RegIta.otf",
    bolditalics: "NimbusSanL-BolIta.otf",
  },
};

const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
};

function getReportFormate(
  text1,
  text2,
  text,
  image,
  tableData,
  signature,
  createdAt,
  created_by,
  practicing_no,
  addendum
) {
  let array = [];
  if (text1 && text2) {
    array.push({ text: text1 });
    
    if (tableData) {
      array.push({
        margin: [0, 10, 0, 30],
        columns: [
          { width: "*", text: "" },
          {
            width: "auto",
            table: {
              headerRows: 1,
              body: tableData,
            },
            layout: {
              paddingLeft: (i, node) => 10,
              paddingRight: (i, node) => 10,
              paddingTop: (i, node) => 5,
              paddingBottom: (i, node) => 5,
            },
          },
          { width: "*", text: "" },
        ],
      });
    }

    array.push({
      text: text2.split("Reported by:")[0],
      margin: [0, -50, 0, 0],
    });
    if (signature) {
      array.push({
        image: signature,
        width: 100,
        margin: [-5, 0, 0, 0],
      });
    }
    array.push({ text: "Reported by", margin: [0, 20, 0, -10] });
    array.push({ text: text2.split("Reported by:")[1] });
  } else {
    if (tableData) {
      array.push({
        columns: [
          { width: "*", text: "" },
          {
            width: "auto",
            magin: [0, 0, 0, 20],
            table: {
              body: tableData,
            },
            layout: {
              paddingLeft: (i, node) => 10,
              paddingRight: (i, node) => 10,
              paddingTop: (i, node) => 5,
              paddingBottom: (i, node) => 5,
            },
          },
          { width: "*", text: "" },
        ],
      });
    }
    array.push({ text: text.split("Reported by:")[0] });
    if (signature) {
      array.push({
        image: signature,
        width: 150,
        margin: [-20, 0, 0, 0],
      });
    }
    array.push({ text: "Reported by", margin: [0, 20, 0, -10] });
    array.push({ text: text.split("Reported by:")[1] });
  }

  // array.push({
  //   text: "Computer generated, no signature required.",
  //   margin: [0, 20, 0, 0],
  // });

  if (addendum) {
    array.push({
      text: `Addendum of this report is done on ${createdAt} transcribed by ${created_by} (${practicing_no})`,
      margin: [0, 20, 0, 100], //tukar spacing
    });
  } else {
    array.push({
      text: `This report is created on ${createdAt} transcribed by ${created_by} (${practicing_no})`,
      margin: [0, 20, 0, 100],
    });
  }
  if (image) {
    array.push({
      margin: [0, 50],
      image: image,
      alignment: "center",
      width:595.35-80,
    });
  }

  return array;
}

const generateHeader=(
  patient_id,PatientSex,usg_no,
  study_date,accesor,OtherPatientIDs,ReferringPhysicianName,
)=>{
  let arr1=[];
  let arr2=[];
  if(patient_id){
    arr1.push(` Patient ID :  ${patient_id || ""}`)
  }

  if(study_date){
    (arr1.length>arr2.length?arr2:arr1).push(` Study Date :   ${study_date || ""}`)
  }

  if(PatientSex){
    (arr1.length>arr2.length?arr2:arr1).push(` Gender : ${PatientSex|| ""} `)
  }
  
  if(usg_no){
    (arr1.length>arr2.length?arr2:arr1).push(" Study No. : " + usg_no)
  }
  
  
  if(accesor){
    (arr1.length>arr2.length?arr2:arr1).push(" Study No. : " + accesor)
  }
  
  // if(PatientBirthDate){
  //   (arr1.length>arr2.length?arr2:arr1).push(` Patient DOB : ${PatientBirthDate|| ""} `)
  // }
  // if(age){
  //   (arr1.length>arr2.length?arr2:arr1).push(" Patient Age : " + age)
  // }
  
  if(OtherPatientIDs){
    (arr1.length>arr2.length?arr2:arr1).push(" NRIC : " + OtherPatientIDs)
  }
  if(ReferringPhysicianName){
    (arr1).push( "Referring Physician : "+ ReferringPhysicianName)
  }



const getBorderbyIndex=(index)=>{
  if(index==(arr1.length-1)){
    return [[true, false, false, true],[false, false, true, true]] //last border;
  }
  else if(index==0){
    return [[true, false, false, false],[false, false, true, false]];//top border
  }
  return [[true, false, false, false], [false, false, true, false]]; //mid border
 }

 let data= arr1.map((_,index)=>{
  let borders=getBorderbyIndex(index);
  if(arr1[index].includes('Referring Physician')){
    return [
      {
        text: arr1[index]||'',
        colSpan:2,
        border: [true,false,true,true]//execption since it will be single column
      },
      {
        text: '',
       colSpan:0,
        border: borders[1],
      }
     ]
  }
  return [
    {
      text: arr1[index]||'',
      colSpan:1,
      border: borders[0]
    },
    {
      text: arr2[index]||'',
      colSpan:1,
      border: borders[1],
    }
   ]
  
 })
  return data
}

async function GeneratePDF(report_data) {
  const {
    logo,
    patient_name,
    patient_id,
    study_type,
    study_date,
    text1,
    text2,
    image,
    table,
    text,
    signature,
    addendumby,
    addendum_at,
    practicing_no,
    accesor,
    is_addebdum,
    PatientSex,
    ReferringPhysicianName,
    OtherPatientIDs,
    usg_no,
    type,
    age,
    PatientBirthDate,
  }=report_data;
  let dd = {
    background: {
      image: await getBase64ImageFromURL(WaterMark),
      width: 400,
      alignment: "center",
      opacity: 0.2,
      absolutePosition: { y: 300 },
    },
    pageMargins: [ 40, 230, 40, 60 ], //[left, top, right, bottom] #pdf-margin
    header: [
      {
        // image: await getBase64ImageFromURL(logo),
        // fit: [400, 80], //tukar report template - change logo size
        // alignment: "center",
        // margin: [0, 0, 0, 0],//logo margins
      },
      {
       style: "tableExample",
       width: "100%",
       margin: [40, 120, 40, 10],
       lineHeight: 1.0,
       table: {
         widths: ["50%", "50%"],
         margin: [40, 10, 40, 10],
         body: [
          [{
            text: ` Name :  ${patient_name?.replaceAll("^", " ") || ""}`,
            colSpan:2,
            border: [true, true, true, false],
            margin: [0, 10, 0, 0]
          },{}],
          [{
            text: ` Study Modality :  ${study_type || ""}`,
            colSpan:2,
            border: [true, false, true, false],
            margin: [0, 0, 0, 0]
          },{}],
           ...generateHeader(patient_id,PatientSex,usg_no,study_date,accesor,OtherPatientIDs,ReferringPhysicianName)
         ],
       }, 
     },
   ],
    ///do not 
    content: [
      ...getReportFormate(
        text1,
        text2,
        text,
        image,
        table,
        signature,
        addendum_at,
        addendumby,
        practicing_no,
        is_addebdum
      ),
    ], //tukar report template - footer
    /*footer: [
      {
        text: "AFFAZ HEALTHCARE GROUP SDN BHD - (1351230-H)",
        alignment: "center",
        fontSize: 7,
        bold: true,
      },
      {
        text: "A wholly owned subsidiary of U.n.i. Klinik Bandar Baru Bangi",
        fontSize: 5,
        alignment: "center",
      },
      {
        text: "B-G-03 & C-G-3A, Street Mall, Jalan Gerbang Wawasan 1, Bangi Gateway, Seksyen 15, 43650 Bandar Baru Bangi, Selangor",
        fontSize: 6,
        alignment: "center",
      },
      {
        text: "Phone : +60389223067 & +60389124087 or WhatsApp : +601159123067",
        fontSize: 7,
        alignment: "center",
      },
    ],*/
    defaultStyle: {
      alignment: "justify",
      fontSize: 10,
      fontFamily: "NimbusSans",
      color: "#000",
      lineHeight: 1.2,
    },
  };
  const pdfDocGenerator = pdfMake.createPdf(dd);
  if(type=='preview'){
    pdfDocGenerator.open();
  }else{
   pdfDocGenerator.download(`${patient_name}.pdf`);
  }
  
}

export default GeneratePDF;
