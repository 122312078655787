const CERVICAL = `CERVICAL RADIOGRAPH AP/LATERAL

Normal cervical  alignment.
Normal vertebral body height. Reduced C5/C6 and C6/C7 disc space.
No fracture or dislocation. Marginal and bridging osteophytes involving C5 till C7 vertebrae.
No prevertebral soft tissue swelling seen.

IMPRESSION: Cervical spondylosis changes.
`

export default CERVICAL;