const CT_PULMONARY = `CT PULMONARY ANGIOGRAPHY 
Findings:
*images are degraded by motion artefacts.
Hypodense filling defects noted within secondary branches of pulmonary arteries supplying the right upper lobe.
The pulmonary trunk, main pulmonary arteries and rest of segmental branches are patent.
Pulmonary trunk is not dilated.

Minimal patchy ground glass changes seen at bibasal region.
No consolidation or cavitating lung lesion.
No suspicious lung or pleural based nodules.
No pleural effusion. 
Trachea, main bronchi and its segmental branches are patent. No endoluminal lesion.
No enlarged mediastinal lymph node.
Heart is enlarged. No pericardial effusion.


Visualized upper abdominal organs are unremarkable within this pulmonary arterial phase study.
No suspicious bone lesion.



Impression:
1. Right segmental pulmonary arterial embolism as mentioned above.
`

export default CT_PULMONARY;
