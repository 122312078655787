import hpupm from '../../assets/images/hpupm.png';
import logo2 from '../../assets/images/logo-2.jpg';
import logo3 from '../../assets/images/Padimedical.png';
import logo4 from '../../assets/images/blanklogo.png';
import logo5 from '../../assets/images/Klinik-anda.png';

const obj=[
    { value: 'logo1', label: <div><img src={hpupm} height="40px" /></div>,img:hpupm },
    { value: 'logo2', label:<div><img src={logo2} height="40px" /></div>,img:logo2 },
    { value: 'logo3',label:<div><img src={logo3} height="40px" /></div>,img:logo3  },
    { value: 'logo4',label:<div><img src={logo4} height="40px" /></div>,img:logo4  },
    { value: 'logo5',label:<div><img src={logo5} height="40px" /></div>,img:logo5  },
]

export default obj;
