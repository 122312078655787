const ABDOMINAL = `ABDOMINAL RADIOGRAPH  SUPINE

FINDINGS
Normal distribution of bowel gases. 
No bowel wall thickening.
Faecal loaded bowel.
No radio-opacity at right hypochondrium or along the urinary tract.
Bones are normal.

IMPRESSION
No significant abnormality.
`

export default ABDOMINAL;