const MAMMOGRAM = `MAMMOGRAM (CC AND MLO IMAGES)

Findings:
BIRADS density a: The breasts are almost entirely fatty.
BIRADS density b: There are scattered areas of fibroglandular density.
BIRADS density c: The breasts are heterogeneously dense, which may obscure small masses.
BIRADS density d: The breasts are extremely dense, which lowers the sensitivity of mammography.
No dominant lesion. 
No suspicious cluster of microcalcifications. 
No architectural distortion.
Axillary lymph nodes with benign appearance.


Extra:
Vascular calcifications.
Scattered microcalcifications.
Axillary lymph nodes with benign appearance.
Skin thickening
Architectural distortion in keeping with previous surgery.  

Impression:
No suspicious mammographic findings. BIRADS Category 1.
 
6 ACR assessment categories depending on the mammographic pathological findings. 
Category 0: Need Additional Imaging Evaluation
Category 1: Negative
Category 2: Benign Finding
Category 3: Probably Benign Finding/ Short Interval Follow-Up Suggested
Category 4: Suspicious Abnormality/Biopsy Should Be Considered
Category 5: Highly Suggestive of Malignancy/Appropriate Action Should Be Taken

Provision:
A normal mammogram report should not delay biopsy if clinically suspicious mass is present. 9% of all breast cancer is not identified by mammography even in retrospect.
Mammogram is only way of detecting early cancer and should be combined with annual clinical breast self examination.
 
Reported by:
Dr Anas Bin Tharek
Radiologist
MD(MSMU), MMedRad (UPM)
MMC (63070)`

export default MAMMOGRAM;