import CommonTable from "./CommonTable";
import { useEffect, useMemo, useState } from "react";
import { commonColumns, seriesColumns } from "./ColumnFactories";

function TableSeries({
    series,
    onDelete,
    refresh,
    hiddenActionBouton,
    hiddenRemoveRow,
    rowEvents,
    rowStyle,
    pagination
}) {
    const [showDelete, setShowDelete] = useState(true);
    useEffect(() => {
        //alert(window.location.pathname)
        if (window.location.pathname == '/padimedical-content') {
            setShowDelete(false)
        }
    }, [window.location.pathname])

    const columns = useMemo(() => [
        commonColumns.RAW,
        seriesColumns.ORTHANC_ID,
        seriesColumns.DESCRIPTION,
        seriesColumns.MODALITY,
        seriesColumns.SERIES_NUMBER,
        ...(!hiddenActionBouton ? [seriesColumns.ACTION(onDelete, refresh)] : [])
    ], [
        hiddenActionBouton, hiddenRemoveRow, onDelete, refresh]);
    const data = useMemo(() => series.map(x => ({
        raw: { ...x },
        ...x
    })), [series]);

    //changes done by rishabh
    if (showDelete) {
        data.map((element, index) => {
            data[index]['Delete'] = <button style={{ display: 'block', margin: 'auto' }} onClick={() => onDelete(element.SeriesOrthancID)} className="btn btn-danger">Delete</button>
        });
    }

    //line 43 change done by rishabh
    return <CommonTable columns={showDelete ? [...columns, { accessor: 'Delete', Header: 'Delete', sort: false, Filter: null, filter: null }] : [...columns]} tableData={data} rowEvents={rowEvents}
        rowStyle={rowStyle} pagination={pagination} />
}

export default TableSeries;
