const PLAIN_CT_BRAIN = `PLAIN CT BRAIN

Findings :
No acute intracranial bleed.
No focal brain parenchymal lesion.
Normal grey and white matter differentiation.
The cerebral sulci, ventricles and basal cisterns are normal.
No obvious skull vault fracture or suture diastasis.
The visualized paranasal sinuses are grossly normal

Impression:
No CT evidence of intracranial bleed.
`

export default PLAIN_CT_BRAIN;
