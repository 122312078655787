const CT_UROGRAPHY = `CT Urography 

Findings:
Right kidney:
Bipolar length of 9.5cm.
No renal calculus seen. No ureteric calculus.
No hydronephrosis or hydroureter.
No perinephric or periureteric fat streakiness.

Left kidney:
Bipolar length of 9.8cm.
No renal calculus seen. No ureteric calculus.
No hydronephrosis or hydroureter.
No perinephric or periureteric fat streakiness.


The urinary bladder well distended. The urinary bladder wall is not thickened. No calculus seen within. 
Prostate is not enlarged.
No ascites.

Visualised lung bases are clear.
No suspicious bone lesions.

Impression:
No focal renal lesion or urolithiasis.
`

export default CT_UROGRAPHY;
