import Chart from "react-apexcharts";
const AreaChart = ({ series, category, name }) => {
  const state = {
    series: [
      {
        name: name,
        data: series,
        color: name === "users" ? "rgb(0, 143, 251)" : "#FF0000",
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: category,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };

  return (
    <Chart
      options={state.options}
      series={state.series}
      type="area"
      height={350}
    />
  );
};

export default AreaChart;
