const CHEST_RADIOGRAPH = `CHEST RADIOGRAPH PA ERECT

Findings:
Mild perihilar interstitial opacity. No focal consolidation.
No pleural effusion.
The heart size is within normal limits.
No hilar or mediastinal abnormality.
The bones are unremarkable.

Impression:
Features suggestive of lower respiratory tract infection.
`

export default CHEST_RADIOGRAPH;