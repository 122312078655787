const HRCT_THORAX = `HRCT Thorax

Findings :
Small nodule seen at apical segment of right upper lobe (0.2cm) and superior segment of left upper lobe (0.3cm).
Consolidation seen at basal segment of right lower lobe.
No lung cavitation.
The trachea, main bronchus and its segmental branches are patent. No endobronchial lesion.
Heart size is normal. No pericardial effusion.
No pleural effusion bilaterally.
Multiple subcentimeter mediastinal lymph nodes, largest measuring 0.5cm.

The visualized upper abdominal organs are unremarkable.
Degenerative spine changes. No suspicious bone lesions.

Impression:
Right basal lung consolidation with multiple lung nodule suggestive of infective lung changes.

`

export default HRCT_THORAX;
