const CHEST_X_RAY = `CHEST RADIOGRAPH PA

Findings:
Both lung fields are clear.
No focal lung lesion.
The heart size is within normal limits.
No hilar or mediastinal abnormality.
The bones are unremarkable.

Impression:

No active lung lesion.
`

export default CHEST_X_RAY;