const SPINE_XRAY = `LUMBOSACRAL RADIOGRAPH AP/LATERAL

Normal lumbar alignment and lordosis.
Normal vertebral body height and disc spaces. 
No fracture or dislocation. No significant marginal osteophytes.
No prevertebral soft tissue swelling. 

IMPRESSION:  Normal lumbar radiograph. Suggest MRI for further evaluation.
`

export default SPINE_XRAY;